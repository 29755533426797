<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div class="row">
				<div class="col">
					<h3 v-if="!mare || !mare.horse_id" class="text-nowrap">2. {{ $t('pax.la_jument') }}</h3>
					<h3 v-else class="text-nowrap">2. {{ mare.horse_nom }}</h3>
				</div>
				<div class="col-auto">
					<div class="input-group filter_button">
					<a v-if="mare && mare.horse_id" class="btn input-group-text btn-secondary" @click="goToSelectionMare">{{ $t('pax.mes_juments') }} <!-- <span class="ml-2 icone"><font-awesome-icon :icon="['far', 'undo-alt']" /></span> --></a>
					</div>
				</div>
			</div>
			<div class="paragraphes">{{ $t('pax.text_jument') }}</div>			
			<div v-for="critere in criteres_local" :key="critere.criteres_id" class="critere" id="myGroup">
				<b-button @click="OpenAllCollapse(critere.criteres_id)" v-b-toggle="'collapse-' + critere.criteres_id"><span>{{ critere.critereslang_label }}</span> <span v-if="refresh_slider && critere.slider" class="badge badge-pill badge-dark px-2">{{ parseFloat(critere.slider).toFixed(1) }}</span> <font-awesome-icon v-if="critere.slider" @click.stop.prevent="critere.slider = null; changeSlider(critere)" :icon="['far', 'eraser']" /> <font-awesome-icon :icon="['far', 'angle-down']" /><font-awesome-icon :icon="['far', 'angle-up']" /></b-button>
				<b-collapse :id="'collapse-' + critere.criteres_id" :ref="'collapse-'+critere.criteres_id" >
					<hr class="mt-0">
					<div class="row">
						<div class="col">
							{{ critere.critereslang_moins }}
						</div>
						<div class="col text-right">
							{{ critere.critereslang_plus }}
						</div>
					</div>
					<input type="range" min="1" max="4" step="0.5" list="tickmarks" class="form-range" @input="changeSlider(critere)" v-model="critere.slider"> 
					<datalist id="tickmarks">
						<option value="1" label="0%"></option>
						<option value="2.5" label="50%"></option>
						<option value="4" label="100%"></option>
					</datalist>
					<div class="marks">
						<div class="row no-gutters">
							<div class="col-auto">1</div>
							<div class="col-auto ml-auto">2</div>
							<div class="col-auto ml-auto">3</div>
							<div class="col-auto ml-auto">4</div>
						</div>
					</div>
					<div class="text-center"><a href="#" @click.prevent="displayHelp(critere.criteres_id, critere.critereslang_label)" class="my-3 btn btn-gfe d-inline-flex align-items-center">{{ $t('pax.aide') }} <font-awesome-icon :icon="['fas', 'info']" class="ml-2"/></a></div>
				</b-collapse>
			</div>

			<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
				<template v-slot:modal-title>
					{{ title_modal }}
				</template>

				<div v-if="!modal_loaded">
					<LoadingSpinner class="col-12" />
				</div>

				<div v-else v-html="content_help">

				</div>
			</b-modal>
		</div>
	</div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import _debounce from 'lodash/debounce'
	import _cloneDeep from 'lodash/cloneDeep';

	export default {
		name: "Mare",
		props: {
			force_refresh: {
				type: String,
				default: () => ( "" )
			},
			mare: {
				type: Object,
				default: () => ( null )
			},
			pax_express: {
				type: Boolean,
				default: () => false
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			}
		},
		mixins: [Navigation, PaxMixin, Tools],
		data () {
			return {
				addOrEditReady: false,
				criteres_local: [],
				lang: 'fr',
				title_modal: '',
				modal_loaded: false,
				content_help: '',
				debouncedSend: _debounce(this.saveCritereMare, 300),
				refresh_slider: true
			}
		},
		created() {
			if(this.pax_express) {
				this.init_component()
			}
		},
		methods: {
			async init_component() {
				this.addOrEditReady = false

				this.lang = this.getConfig('lang')

				this.criteres_local = await this.getCriteres(this.lang)
				
				for (let index = 0; index < this.criteres_local.length; index++) {
					const element = this.criteres_local[index];

					element['critereslang_label'] = element.criteres_lang[0].critereslang_label
					element['critereslang_moins'] = element.criteres_lang[0].critereslang_moins
					element['critereslang_plus'] = element.criteres_lang[0].critereslang_plus
					element['critereslang_description'] = element.criteres_lang[0].critereslang_description
				}

				if(this.mare && this.mare.horse_id) {
					const criteres_mare = await this.getCaracterisationByHorse(this.mare.horse_id)
					let nb_slider = 0
					criteres_mare.forEach(c_mare => {
						const critere_index = this.criteres_local.findIndex((c) => c.criteres_id == c_mare.caracterisation_criteres)
						if(critere_index > -1) {
							let current_critere = _cloneDeep(this.criteres_local[critere_index])
							current_critere.slider = c_mare.valeur
							this.$set(this.criteres_local, critere_index, current_critere)
							nb_slider++
						}
					})
					if(nb_slider > 2) {
						this.$emit('update:criteres', this.criteres_local)
					}
				}

				this.addOrEditReady = true
			},

			changeSlider(critere) {
				this.refresh_slider = false
				let nb_slider = 0

				for(let index in this.criteres_local) {
					if(this.criteres_local[index].slider) {
						nb_slider++
					}
				}

				if(nb_slider > 2) {
					this.$emit('update:criteres', this.criteres_local)
				}
				else {
					this.$emit('update:criteres', [])
				}

				if(this.mare && this.mare.horse_id) {
					this.debouncedSend(critere)
				}
				this.refresh_slider = true
			},

			saveCritereMare(critere) {
				this.saveCaracterisationByHorse(this.mare.horse_id, critere.criteres_id, critere.slider)
			},

			OpenAllCollapse(criteres_id) {
				for (var i = 0; i < this.criteres_local.length; i++) {
					if(criteres_id != this.criteres_local[i].criteres_id) {
						this.$refs["collapse-" + this.criteres_local[i].criteres_id][0].$data.show = false
					}
				}
      		},

			async displayHelp(criteres_id, critere_label) {
				this.title_modal = critere_label
				this.$refs["modal"].show()
				this.modal_loaded = false

				let current_critere = this.criteres_local.find(elem => elem.criteres_id == criteres_id)
				this.content_help = current_critere.critereslang_description
				
				this.modal_loaded = true
			},

			goToSelectionMare() {
				if(this.stallions.length > 0) {
					const ids = this.stallions.map(stallion => stallion.horse_id)
					this.$router.push({ name: 'pax', params: { stallion_ids: ids.join(',') }})
				}
				else {
					this.$router.push({ name: 'paxSuggestion' })
				}
			}	
		},
		watch: {
			force_refresh() {
				this.init_component()
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
